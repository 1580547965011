<template>
    <div class="skhvault">
        <SKHVaultBox :amount="available" :title="`${$t('skhVault.available')} (SKH)`" :skh-price="skhPrice" />
        <SKHVaultBox :amount="collected" :title="`${$t('skhVault.collected')} (SKH)`" :skh-price="skhPrice" />
        <SKHVaultBox :amount="cPaidOut" :title="`${$t('skhVault.paidOut')} (SKH)`" :skh-price="skhPrice" />
    </div>
</template>

<script>
import {pollingMixin} from "@/mixins/polling.js";
import gql from "graphql-tag";
import {formatHexToInt, timestampToDate} from "@/filters.js";
import SKHVaultBox from "@/components/SKHVaultBox.vue";
import {VAULT_CONTRACT_ADDRESS} from "@/utils/constants.js";
import {toBigNumber, toHex} from "@/utils/big-number.js";

export default {
    name: "SKHVault",
    components: {SKHVaultBox},

    mixins: [pollingMixin],

    props: {
        /** Maximum amount of displayed blocks */
        maxBlocks: {
            type: Number,
            default: 5,
        },
    },

    data() {
        return {
            available: '0x0',
            collected: '0x0',
            blocks: [],
        }
    },

    computed: {
        skhPrice() {
            return this.$store.state.tokenPrice;
        },

        cPaidOut() {
            const bPaidOut = toBigNumber(this.collected).minus(this.available);

            return bPaidOut.toNumber() < 0 || this.available === '0x0' ? '0x0' : toHex(bPaidOut);
        }
    },

    mounted() {
        setTimeout(() => {
            this.updateCollected();
            this.updateAvailable();

        }, 2050);

        this._polling.start(
            'update-skh-vault-collected',
            () => {
                this.updateCollected();
            },
            9000
        );

        this._polling.start(
            'update-skh-vault-available',
            () => {
                this.updateAvailable();
            },
            10500
        );
    },

    methods: {
        async updateCollected() {
            this.collected = await this.getSkhTreasuryTotalAmount();

            // tmp
        },

        async updateAvailable() {
            this.available = await this.getAvailable();
        },

        /**
         * @returns {Promise<Array>}
         */
        async getSkhTreasuryTotalAmount() {
            const data = await this.$apollo.query({
                query: gql`
                    query GetSkhTreasuryTotalAmount {
                        skhTreasuryTotal
                    }
                `,
                fetchPolicy: 'network-only',
            });

            return data.data && data.data.skhTreasuryTotal || '0x0';
        },

        async getAvailable(address = VAULT_CONTRACT_ADDRESS) {
            const data = await this.$apollo.query({
                query: gql`
                    query GetAccountBalance($address: Address!) {
                        account(address: $address) {
                            balance
                        }
                    }
                `,
                variables: { address },
                fetchPolicy: 'network-only',
            });

            return data.data && data.data.account.balance || '0x0';
        },

        timestampToDate,
        formatHexToInt,
    }
}
</script>

<style lang="scss">
.skhvault {
    width: 100%;

    display: flex;
    gap: 16px;

    > * {
        flex: 1;
    }
}

@include media-max($bp-large) {
    .skhvault {
        flex-direction: column;
    }
}
</style>
