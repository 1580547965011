var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-delegation-list-dt"},[(!_vm.delegationsByAddressError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.dItems,"disable-infinite-scroll":!_vm.hasNext,"mobile-view":_vm.mobileView,"loading":_vm.loading,"first-m-v-column-width":"5","infinite-scroll":"","fixed-header":""},on:{"fetch-more":_vm.fetchMore},scopedSlots:_vm._u([{key:"column-validator",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(value)?[_vm._v(" "+_vm._s(value.stakerInfo && value.stakerInfo.name ? value.stakerInfo.name : 'Unknown')+", "+_vm._s(_vm._f("formatHexToInt")(value.id))+" ")]:_vm._e()],2)]):(value)?[_vm._v(" "+_vm._s(value.stakerInfo && value.stakerInfo.name ? value.stakerInfo.name : 'Unknown')+", "+_vm._s(_vm._f("formatHexToInt")(value.id))+" ")]:_vm._e()]}},{key:"column-amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})]]}},{key:"column-rewards",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})]]}}],null,false,376743968)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.delegationsByAddressError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }