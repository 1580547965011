var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"narrow-container"},[_c('div',{staticClass:"test-controls"},[_c('button',{on:{"click":_vm.onNewItemsClick}},[_vm._v("Set new items")]),_c('button',{on:{"click":_vm.onNoItemsClick}},[_vm._v("No items")]),_c('button',{on:{"click":_vm.onPushClick}},[_vm._v("Push item")]),_c('button',{on:{"click":_vm.onPrependClick}},[_vm._v("Prepend item")]),_c('button',{on:{"click":_vm.onUpdateClick}},[_vm._v("Update item on index 1")]),_c('button',{on:{"click":_vm.onUpdateCellClick}},[_vm._v("Update cell on index 1")]),_c('button',{on:{"click":_vm.onMobileViewClick}},[_vm._v("Toggle mobile view")])]),_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.items,"total-items":_vm.items.length,"items-per-page":20,"mobile-view":_vm.mobileView,"height":"400px","use-pagination":"","fixed-header":""},scopedSlots:_vm._u([{key:"before-table",fn:function(){return [_c('div',{staticStyle:{"text-align":"end","padding-bottom":"4px"}},[_c('h3',[_vm._v("Complex data table")]),_c('f-pagination',{attrs:{"total-items":_vm.items.length,"items-per-page":20},on:{"page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"pages",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.currPage)+" / "+_vm._s(data.numPages)+" ("+_vm._s(data.totalItems)+") ")]}}])})],1)]},proxy:true},{key:"after-table",fn:function(){return [_c('div',[_vm._v("After table slot")])]},proxy:true},{key:"column-country_name",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',[_c('span',{staticClass:"column-label"},[_vm._v(_vm._s(column.label))]),_vm._v(" "),_c('span',[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(value))])])]):_c('div',[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(value))])])]}},{key:"column-country_code",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',[_c('span',{staticClass:"column-label"},[_vm._v(_vm._s(column.label))]),_vm._v(" "+_vm._s(value)+" ")]):_c('div',[_c('div',{staticClass:"tpl-test"},[_vm._v(_vm._s(value))])])]}}])}),_c('h3',[_vm._v("Data table with no items")]),_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns}}),_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.items,"total-items":_vm.items.length,"items-per-page":20,"fixed-header":""},scopedSlots:_vm._u([{key:"before-table",fn:function(){return [_c('h3',[_vm._v("Default data table")])]},proxy:true},{key:"after-table",fn:function(){return [_c('div',[_vm._v("After table slot")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }